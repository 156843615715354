








import { Component, Prop, Vue } from "vue-property-decorator";
import DecorateWidget from "@/components/decorate/decorate-widget.vue";
import DecoratePhone from "@/components/decorate/decorate-phone.vue";
import DecorateAttr from "@/components/decorate/decorate-attr.vue";

@Component({
  components: {
    DecorateWidget,
    DecoratePhone,
    DecorateAttr,
  },
})
export default class DecorateIndex extends Vue {
  /** S data **/
  id!: any;
  component = [
    {
      name: "header",
      operate: ["hidden"],
    },
    {
      name: "adv",
    },
    {
      name: "goodsgroup",
    },
    {
      name: "rubik",
    },
    {
      name: "title",
    },
    {
      name: "blank",
    },
    {
      name: "brandhall",
    },
    {
      name: "festival",
    },
    // {
    //   name: "fixed",
    //   operate: ["hidden"],
    // },
    {
      name: "banner",
      operate: ["hidden"],
    },
    {
      name: "seckill",
    },

    {
      name: "footer",
      operate: ["hidden"],
    },
  ];

  pagesInfo = {
    name: "PC商城首页",
    common: {
      background_color: "#FFFFFF",
      background_type: "1",
    },
  };

  pageData = [
    // 页头
    {
      title: "头部",
      name: "header",
      show: 1,
      forbid: true,
      content: {
        data: [],
      },
      style: {},
    },
    // 轮播
    {
      title: "轮播",
      name: "banner",
      show: 1,
      forbid: true,
      content: {
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/bg1.jpg",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/bg2.jpg",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/bg3.jpg",
            link: {},
          },
        ],
      },
      style: {},
    },
    // 标题
    {
      title: "标题",
      name: "title",
      show: 1,
      content: {
        style: 1,
        title: "端午礼盒 好价纷呈",
        subtitle: "热销香粽 端午限定 精选品牌 正品保证",
        hidden_subtitle: 0,
        show_more: 1,
        more_title: "更多",
        link: {},
      },
      styles: {
        align: "flex-end",
        root_bg_color: "",
        bg_color: "#ffffff",
        title_color: "#333333",
        title_font_size: 36,
        title_line_height: 78,
        subtitle_color: "#999999",
        subtitle_font_size: 32,
        subtitle_line_height: 36,
        more_color: "#999999",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
        border_radius_top: 0,
        border_radius_bottom: 0,
      },
    },
    // 图片魔方
    {
      title: "图片魔方",
      name: "rubik",
      show: 1,
      content: {
        style: 6,
        bg_img: "https://cardmall-online.guanaitong.com/img/new/black-bg.png",
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/duanwu01.jpg?v=1.0.6",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/duanwu04.jpg?v=1.0.6",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/duanwu05.jpg?v=1.0.6",
            link: {},
          },
        ],
      },
      styles: {
        border_radius: 0,
        root_bg_color: "",
        line_color: "#e5e5e5",
        font_color: "#333",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
      },
    },
    // 商品组
    {
      title: "商品组",
      name: "goodsgroup",
      show: 1,
      content: {
        data: [],
        title: "主标题",
        subtitle: "副标题",
        show_more: 1,
        show_adv: 1,
        adv_url: "",
        adv_link: {},
        goods_type: 1,
        category: {
          id: "",
          name: "",
          num: 1,
        },
      },
      styles: {},
    },
    // 图片魔方
    {
      title: "图片魔方",
      name: "rubik",
      show: 1,
      content: {
        style: 1,
        bg_color: "#fff",
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/choose.png?v=1.0.6",
            link: {},
          },
        ],
      },
      styles: {
        border_radius: 0,
        root_bg_color: "",
        line_color: "#e5e5e5",
        font_color: "#333",
        padding_top: 20,
        padding_horizontal: 0,
        padding_bottom: 20,
      },
    },
    // 标题
    {
      title: "标题",
      name: "title",
      show: 1,
      content: {
        style: 1,
        title: "两大卡券产品 随心选",
        subtitle: "",
        hidden_subtitle: 0,
        show_more: 1,
        more_title: "更多",
        link: {},
      },
      styles: {
        align: "flex-end",
        root_bg_color: "",
        title_color: "#000",
        title_font_size: 36,
        title_line_height: 78,
        subtitle_color: "#000",
        subtitle_font_size: 32,
        subtitle_line_height: 36,
        more_color: "#999999",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
        border_radius_top: 0,
        border_radius_bottom: 0,
      },
    },
    // 图片魔方
    {
      title: "图片魔方",
      name: "rubik",
      show: 1,
      content: {
        style: 1,
        bg_color: "#fff",
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/two-cards-banner-duanwu.jpg?v=1.0.6",
            link: {},
          },
        ],
      },
      styles: {
        border_radius: 0,
        root_bg_color: "",
        line_color: "#e5e5e5",
        font_color: "#333",
        padding_top: 20,
        padding_horizontal: 0,
        padding_bottom: 20,
      },
    },
    // 标题
    {
      title: "标题",
      name: "title",
      show: 1,
      content: {
        style: 1,
        title: "品牌馆",
        subtitle: "品牌荟萃 好价纷呈",
        hidden_subtitle: 0,
        show_more: 1,
        more_title: "更多",
        link: {},
      },
      styles: {
        bg_img: "https://cardmall-online.guanaitong.com/img/new/black-bg2.jpg",
        align: "flex-start",
        root_bg_color: "",
        title_color: "#fff",
        title_font_size: 36,
        title_line_height: 78,
        subtitle_color: "#fff",
        subtitle_font_size: 32,
        subtitle_line_height: 36,
        more_color: "#999999",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
        border_radius_top: 0,
        border_radius_bottom: 0,
      },
    },
    // 品牌馆
    {
      title: "品牌馆",
      name: "brandhall",
      show: 1,
      content: {
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/brand-banner1.png",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/brand-banner2.png",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/brand-banner3.png",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/brand-banner4.png",
            link: {},
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/brand-banner5.png",
            link: {},
          },
        ],
      },
      styles: {
        bg_img: "https://cardmall-online.guanaitong.com/img/new/black-bg2.jpg",
        root_bg_color: "",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
        border_radius_top: 0,
        border_radius_bottom: 0,
      },
    },
    // 节日卡券
    {
      title: "节日卡券",
      name: "festival",
      show: 1,
      content: {
        data: [
          {
            url: "https://cardmall-online.guanaitong.com/img/new/holiday-benefits-duanwu.jpg",
            link: {},
            text: "端午",
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/holiday-benefits-38.jpg",
            link: {},
            text: "佳人",
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/holiday-benefits-newyear.jpg",
            link: {},
            text: "新年",
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/home-card1.jpg",
            link: {},
            text: "生日",
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/home-card3.jpg",
            link: {},
            text: "中秋",
          },
          {
            url: "https://cardmall-online.guanaitong.com/img/new/home-card2.jpg",
            link: {},
            text: "更多",
          },
        ],
      },
      styles: {
        position: "right",
        root_bg_color: "",
        text_color: "#333333",
        active_color: "#ea5514",
        padding_top: 0,
        padding_horizontal: 0,
        padding_bottom: 0,
        border_radius_top: 0,
        border_radius_bottom: 0,
      },
    },
    // footer
    {
      title: "底部",
      name: "footer",
      show: 1,
      forbid: true,
      content: {
        data: [
          {
            url: "",
            name: "",
          },
          {
            url: "",
            name: "",
          },
          {
            url: "",
            name: "",
          },
        ],
      },
      style: {},
    },
    // 导航
    // {
    //   title: "固定导航",
    //   name: "fixed",
    //   show: 1,
    //   forbid: true,
    //   content: {
    //     style: 1,
    //     data: [
    //       {
    //         type: "nav",
    //         icon: "",
    //         select_icon: "",
    //         name: "",
    //         link: {},
    //       },
    //     ],
    //   },
    //   style: {},
    // },
  ];
  /** E data **/

  /** S computed **/

  /** E computed **/

  /** S methods **/

  async getPages() {
    this.$store.commit("setPagesInfo", this.pagesInfo);
    this.$store.commit("setPagesData", this.pageData);
    this.$store.commit("setClient", "pc");
    if (this.id) {
      this.$store.dispatch("getPages", { id: this.id });
    }
  }
  /** E methods **/
  /** S life cycle **/
  created() {
    this.id = this.$route.query.id;
    document.body.setAttribute("style", "min-width: 1800px");
    this.getPages();
  }

  beforeDestroy() {
    document.body.setAttribute("style", "");
  }
  /** E life cycle **/
}
